import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Card, Divider, Spin, Alert } from "antd";
import { ResponsiveLine } from "@nivo/line";
import "./SearchResults.css";

const SearchResults = ({ country }) => {
  const [countryData, setCountryData] = useState(null);
  const [localTime, setLocalTime] = useState("");
  const [timeDifference, setTimeDifference] = useState("");
  const [exchangeData, setExchangeData] = useState([]);
  const [latestExchangeRate, setLatestExchangeRate] = useState({
    rate: 0,
    date: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { countryName } = useParams(); // URL 경로에서 국가명을 가져옵니다.
  const navigate = useNavigate();

  // 국가 데이터가 변경될 때마다 브라우저 탭 제목 업데이트
  useEffect(() => {
    if (countryData && countryData.name && countryData.name.engName) {
      document.title = `WTrip - ${countryData.name.engName}`;
    } else {
      document.title = "WTrip";
    }
  }, [countryData]);

  // 국가 데이터를 불러오는 함수
  const fetchCountryData = useCallback(async () => {
    setIsLoading(true);
    const apiUrl = "https://apis.uiharu.dev/flags/api.php";
    const corsFixUrl = `https://apis.uiharu.dev/fixcors/api.php?url=${apiUrl}`;

    try {
      const response = await axios.post(corsFixUrl, { alpha3: countryName });
      if (response.data.StatusCode === 200) {
        setCountryData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching country data:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [countryName]); // 여기서 country 대신 countryName을 사용합니다.

  // 환율 데이터 처리 함수
  const processExchangeData = useCallback(
    (data) => {
      // countryData가 null이 아닐 때만 처리를 수행
      if (countryData) {
        const uniqueData = new Map();
        let latestDate = new Date(0); // 초기 날짜를 1970-01-01로 설정
        let latestRate = 0;

        data.forEach((item) => {
          const itemDate = new Date(item.date);
          const rate = parseFloat(item.deal_bas_r);

          uniqueData.set(item.date, rate);

          if (itemDate > latestDate) {
            latestDate = itemDate;
            latestRate = rate;
          }
        });

        setLatestExchangeRate({
          rate: latestRate,
          date: latestDate.toISOString().split("T")[0],
        });

        const processedData = Array.from(uniqueData, ([date, deal_bas_r]) => ({
          x: date,
          y: deal_bas_r,
        }));

        setExchangeData([
          { id: countryData.CurrencyName, data: processedData },
        ]);
      }
    },
    [countryData] // 옵셔널 체이닝을 사용하여 countryData가 null일 경우를 처리
  );

  // 환율 데이터를 불러오는 함수
  const fetchExchangeData = useCallback(async () => {
    // countryData가 null이거나 CurrencyName이 없는 경우 함수를 종료
    if (!countryData || !countryData.CurrencyName) {
      return;
    }

    try {
      const currencyCode = countryData.CurrencyName;
      const response = await axios.post(
        "https://apis.uiharu.dev/exchange/api.php",
        { cur_unit: currencyCode, dayrange: 7 }
      );
      if (response.data.StatusCode === 200) {
        processExchangeData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching exchange data:", error);
    }
  }, [countryData, processExchangeData]); // countryData를 의존성 배열에 추가

  // 여러 시간대를 가진 국가들의 alpha3 코드
  const multipleTimeZonesCountries = [
    "USA",
    "RUS",
    "CAN",
    "AUS",
    "BRA",
    "MEX",
    "KAZ",
    "IDN",
  ];

  // 로딩 상태를 표시하는 컴포넌트
  const LoadingIndicator = () => (
    <div className="loading-indicator">
      <Spin size="large" />
    </div>
  );

  // 에러 메시지를 표시하는 컴포넌트
  const ErrorMessage = ({ message }) => (
    <div className="error-message">
      <Alert message="Error" description={message} type="error" showIcon />
    </div>
  );

  // 현재 시간과 시간 차이 계산 함수
  const calculateTimeInfo = useCallback(
    (timeZone) => {
      const now = new Date();
      const options = { hour: "2-digit", minute: "2-digit", second: "2-digit" };

      // 해당 국가의 현재 시간
      const localTimeFormatted = new Intl.DateTimeFormat("en-US", {
        ...options,
        timeZone,
      }).format(now);
      setLocalTime(localTimeFormatted);

      // 한국을 선택한 경우 시간 차이를 표시하지 않음
      if (country === "KOR") {
        setTimeDifference("");
        return;
      }

      // 한국과의 시간 차이 계산
      const koreaTime = new Date().toLocaleString("en-US", {
        timeZone: "Asia/Seoul",
      });
      const countryTime = new Date().toLocaleString("en-US", { timeZone });

      const diffInMilliseconds = new Date(countryTime) - new Date(koreaTime);
      const diffInMinutes = diffInMilliseconds / 1000 / 60;
      const hours = Math.floor(Math.abs(diffInMinutes) / 60);
      const minutes = Math.abs(diffInMinutes) % 60;

      // 시간 차이가 없는 경우 '동일'로 표시
      if (diffInMilliseconds === 0) {
        setTimeDifference("한국과의 시간 차이: <strong>동일</strong>");
        return;
      }

      const timeDifferenceString = `한국과의 시간 차이: <strong>${hours}시간 ${
        minutes > 0 ? `${minutes}분` : ""
      } ${diffInMilliseconds > 0 ? "느림" : "빠름"}</strong>`;

      setTimeDifference(timeDifferenceString);
    },
    [country]
  );

  // 컴포넌트 마운트 시 국가 데이터 불러오기
  useEffect(() => {
    if (countryName) {
      // 여기서도 country 대신 countryName을 사용합니다.
      fetchCountryData();
    }
  }, [countryName, fetchCountryData]);

  // 환율 데이터 불러오기
  useEffect(() => {
    if (countryData && countryData.CurrencyName) {
      fetchExchangeData();
    }
  }, [countryData, fetchExchangeData]);

  // 시간 업데이트
  useEffect(() => {
    if (countryData && countryData.capitalTimeZone) {
      const interval = setInterval(() => {
        calculateTimeInfo(countryData.capitalTimeZone);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countryData, calculateTimeInfo]);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  // 여행경보 레벨을 문자열로 변환하는 함수
  const getAlertLevelText = (level) => {
    switch (level) {
      case 1:
        return "1단계(여행유의)";
      case 2:
        return "2단계(여행자제)";
      case 3:
        return "3단계(출국권고)";
      case 4:
        return "4단계(여행금지)";
      default:
        return "여행경보 없음";
    }
  };
  
  // "자세히 보기" 버튼의 클릭 이벤트 핸들러
  const handleMoreDetailsClick = () => {
    if (countryData && countryData.CurrencyName) {
      navigate(`/exchange/${countryData.CurrencyName}`);
    }
  };

  return (
    <div className="search-results-container">
      {countryData && (
        <div className="cards-container">
          {countryData.iso.alpha3 === "KOR" ? (
            // 한국인 경우 country-card와 time-card를 별도로 표시
            <>
              <Card
                className="country-card"
                hoverable
                style={{ cursor: "unset" }}
                cover={
                  <img
                    alt={`Flag of ${countryData.name.engName}`}
                    src={countryData.flag}
                  />
                }
              >
                <Card.Meta
                  title={countryData.name.name}
                  description={`ISO Code: ${countryData.iso.alpha3}`}
                />
              </Card>

              <Card className="time-card" hoverable>
                <div style={{ textAlign: "center", margin: "20px" }}>
                  <h2>{`${countryData.name.name} 수도의 현재 시간`}</h2>
                  <p
                    style={{
                      fontSize: "24px",
                      color: "black",
                      margin: "10px 0",
                    }}
                  >
                    {localTime}
                  </p>
                </div>
                <div style={{ padding: "0 20px" }}>
                  <div dangerouslySetInnerHTML={{ __html: timeDifference }} />
                  {multipleTimeZonesCountries.includes(
                    countryData.iso.alpha3
                  ) && <p>(다수의 시간대 중 수도의 시간대)</p>}
                </div>
              </Card>
            </>
          ) : (
            // 다른 국가인 경우 alert-card와 combined-card를 표시
            <>
              {countryData.alert && (
                <Card
                  className="alert-card"
                  hoverable
                  style={{
                    cursor: "unset",
                    height: "100%",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  <div style={{ textAlign: "center", marginBottom: "-25px" }}>
                    <a
                      href={countryData.alertImageUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        alt={`Travel alert for ${countryData.name.engName}`}
                        src={countryData.alertImageUrl}
                        style={{
                          width: "100%",
                          maxWidth: "500px",
                          height: "auto",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      />
                    </a>
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      fontSize: "1.25em",
                      marginTop: "10px",
                      marginBottom: "-10px",
                    }}
                  >
                    <ul style={{ paddingLeft: 0, marginLeft: "50px" }} >
                      {" "}
                      {/* 여기부터 리스트 시작 */}
                      <li>
                        <strong>여행경보 레벨:</strong>{" "}
                        {getAlertLevelText(countryData.alert.alarm_lvl)}
                      </li>
                      <li>
                        <strong>지역 유형:</strong>{" "}
                        {countryData.alert.region_ty}
                      </li>
                      <li>
                        <strong>주의사항:</strong> {countryData.alert.remark}
                      </li>
                      <li>
                        <strong>외교부 업데이트 날짜:</strong>{" "}
                        {countryData.alert.written_dt}
                      </li>
                      {/*
		<li>
		  <strong>API 업데이트 날짜:</strong> {countryData.alert.update_date} 
        </li> */}
                    </ul>{" "}
                    {/* 리스트 끝 */}
                  </div>
                </Card>
              )}

              <Card
                className="combined-card"
                hoverable
                style={{
                  margin: "20px 0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <img
                    alt={`Flag of ${countryData.name.engName}`}
                    src={countryData.flag}
                    style={{ width: "80%", maxWidth: "500px", height: "auto", marginTop: "-15px" }}
                  />
                </div>
                <div
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    marginTop: "-15px",
                  }}
                >
                  <h2 style={{ fontSize: "2em", margin: "10px 0" }}>
                    {countryData.name.name}({countryData.name.engName})
                  </h2>
                  <p>
                    ISO 코드: 알파 3 - {countryData.iso.alpha3} / 알파 2 -{" "}
                    {countryData.iso.alpha2}
                  </p>
                  <p style={{ marginTop: "-15px" }}>
                    통화명: {countryData.CurrencyName} / ICU 로케일:{" "}
                    {countryData.ICUlocale}
                  </p>
                </div>
                <Divider
                  style={{ height: "2px", backgroundColor: "lightgrey" }}
                />
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <h2
                    style={{ fontSize: "1.5em" }}
                  >{`${countryData.name.name} 수도의 현재 시간`}</h2>
                  <p
                    style={{
                      fontSize: "2em",
                      color: "black",
                      margin: "10px 0",
                    }}
                  >
                    {localTime}
                  </p>
                  <div style={{ padding: "0 20px" }}>
                    <div dangerouslySetInnerHTML={{ __html: timeDifference }} />
                    {multipleTimeZonesCountries.includes(
                      countryData.iso.alpha3
                    ) && (
                      <p style={{ marginTop: "-3px" }}>
                        (다수의 시간대 중 수도의 시간대(
                        {countryData.capitalTimeZone}))
                      </p>
                    )}
                  </div>
                </div>
              </Card>
            </>
          )}
        </div>
      )}

      <Card
  className="exchange-card"
  hoverable
  style={{ width: window.innerWidth < 768 ? "90%" : "70%", marginBottom: "20px" }}
>
  <div style={{ position: 'relative', textAlign: 'center' }}>
    <h2>
      {countryData &&
        `${countryData.name.name} (${countryData.CurrencyName})의 환율 - ${latestExchangeRate.rate.toFixed(2)}`}
    </h2>
    <Button
      type="primary"
      onClick={handleMoreDetailsClick}
      style={{
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)'
      }}
    >
      자세히 보기
    </Button>
  </div>
  <p style={{ textAlign: "center", fontSize: "small", marginTop: -15 }}>
    {latestExchangeRate.date &&
      new Date(latestExchangeRate.date).toLocaleDateString("ko-KR")} 기준
  </p>
  <div style={{ height: 400 }}>
          <ResponsiveLine
            data={exchangeData}
            margin={{
              top: 20,
              right: 50,
              bottom: window.innerWidth < 768 ? 90 : 60, // 모바일에선 X축 라벨(날짜)이 겹치므로 수치를 크게 함
              left: 80,
            }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: window.innerWidth < 768 ? 45 : 0, // 모바일에선 틱 레이블을 45도 회전
              legend: "날짜",
              legendOffset: window.innerWidth < 768 ? 80 : 50, // 모바일에서 더 큰 legendOffset 적용
              legendPosition: "middle",
            }}
            axisLeft={{
              legend: "환율",
              legendOffset: -70,
              legendPosition: "middle",
              format: (value) => `${Number(value).toFixed(2)}`,
            }}
            colors={{ scheme: "nivo" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={({ point }) => (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
                }}
              >
                <div>날짜: {point.data.xFormatted}</div>
                <div>환율: {point.data.yFormatted}</div>
              </div>
            )}
          />
        </div>
      </Card>
    </div>
  );
};

export default SearchResults;
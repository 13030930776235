import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { Card, Select, Typography, Modal } from "antd";
import {
  ExclamationCircleOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
import { ResponsiveLine } from "@nivo/line";
import { median, mean, min, max } from "d3-array";

const { Title } = Typography;
const { Option } = Select;

const ExchangeInfo = () => {
  const { currency } = useParams();
  const navigate = useNavigate();
  // 날짜 범위 정보를 저장하기 위한 상태 변수
  const [dateRangeInfo, setDateRangeInfo] = useState({
    actualDataRange: "",
    forecastDataRange: "",
  });
  const [data, setData] = useState([]);
  // 세션 스토리지에서 dayRange 값을 불러오거나 기본값을 설정
  const [dayRange, setDayRange] = useState(
    sessionStorage.getItem("dayRange") || "3"
  );
  /*
  const [forecastData, setForecastData] = useState({
    datasets: [],
    forecast: [],
  });
  */
  const [stats, setStats] = useState({
    min: 0,
    max: 0,
    median: 0,
    mean: 0,
    mode: 0,
  });
  const [showWarning, setShowWarning] = useState(true);
  const [prevDayRange, setPrevDayRange] = useState(dayRange);

  const fetchData = useCallback(async () => {
    const formatDataForGraph = (data) => {
      // 요청한 날짜 범위보다 많은 데이터가 있는 경우 가장 오래된 데이터를 제외
      if (data.length > dayRange) {
        data = data.slice(1);
      }
      return [
        {
          id: currency,
          data: data.map((item) => ({
            x: item.date,
            y: parseFloat(item.deal_bas_r),
          })),
        },
      ];
    };

    const calculateStats = (data) => {
      const rates = data.map((item) => parseFloat(item.deal_bas_r));
      const modeValue = calculateMode(rates);
      setStats({
        min: min(rates),
        max: max(rates),
        median: median(rates),
        mean: mean(rates),
        mode: modeValue,
      });
    };

    // 환율 예측 관련 코드
    // 환율 예측 데이터를 가져오는 함수
    const fetchForecastData = async () => {
      try {
        const response = await axios.post(
          "https://apis.uiharu.dev/fixcors/api.php?url=https://apis.uiharu.dev/exchange/forecast/api.php",
          { currency: currency }
        );
        const result = response.data;
        if (result.StatusCode === 200) {
          // 과거 데이터 처리
          const formattedDatasets = result.Datasets.map((d) => ({
            x: d.date,
            y: parseFloat(d.deal_bas_r),
          }));

          // 예측 데이터 처리
          const formattedForecast = result.Forecast.map((f) => ({
            x: f.date,
            y: parseFloat(f.predicted_rate),
          }));

          // 데이터의 시작과 종료 날짜 추출
          const actualDataStart = formattedDatasets[0].x;
          const actualDataEnd =
            formattedDatasets[formattedDatasets.length - 1].x;
          const forecastDataStart = formattedForecast[0].x;
          const forecastDataEnd =
            formattedForecast[formattedForecast.length - 1].x;

          // 날짜 범위 정보 상태 업데이트
          setDateRangeInfo({
            actualDataRange: `${actualDataStart} ~ ${actualDataEnd}`,
            forecastDataRange: `${forecastDataStart} ~ ${forecastDataEnd}`,
          });

          // 과거 데이터와 예측 데이터를 합치고, 날짜 순으로 정렬
          const combinedData = [
            ...formattedDatasets,
            ...formattedForecast,
          ].sort((a, b) => new Date(a.x) - new Date(b.x));

          setData([{ id: currency, data: combinedData }]);

          return true;
        } else {
          navigate("/error/404");
        }
      } catch (error) {
        console.error("Failed to fetch forecast data:", error);
        navigate("/error/404");
      }
    };

    try {
      // axios를 사용하여 API 호출
      const response = await axios.post(
        "https://apis.uiharu.dev/exchange/api.php",
        {
          cur_unit: currency,
          dayrange: dayRange,
        }
      );
      const result = response.data;
      if (dayRange === "1000") {
        fetchForecastData();
      } else {
        if (result.StatusCode === 200) {
          const formattedData = formatDataForGraph(result.data);
          setData(formattedData);
          calculateStats(result.data);
        } else if (result.StatusCode === 400) {
          navigate("/error/404");
        }
      }
    } catch (error) {
      console.error("Failed to fetch data:", error);
      navigate("/error/404");
    }
  }, [currency, dayRange, navigate]);

  useEffect(() => {
    document.title = `WTrip - 환율 정보: ${currency}`;
    fetchData();
  }, [currency, dayRange, fetchData]);

  useEffect(() => {
    setPrevDayRange(dayRange.toString());
  }, [dayRange]);

  // 최빈값 계산
  const calculateMode = (values) => {
    let frequency = {};
    let maxFreq = 0;
    let mode = [];
    for (let val of values) {
      frequency[val] = (frequency[val] || 0) + 1;
      if (frequency[val] > maxFreq) {
        maxFreq = frequency[val];
      }
    }

    for (let key in frequency) {
      if (frequency[key] === maxFreq) {
        mode.push(parseFloat(key));
      }
    }

    return mode.length === 1 ? mode[0] : mode;
  };

  const handleDayRangeChange = (value) => {
    if ((value === "100" || value === "365") && showWarning) {
      Modal.confirm({
        title: "경고",
        icon: <ExclamationCircleOutlined />,
        maskClosable: true,
        content: (
          <div>
            데이터가 많아 느려질 수 있습니다.
            <br />
            그래도 계속 하시겠습니까?
          </div>
        ),
        onOk: () => {
          setShowWarning(false);
          setDayRange(value);
        },
        cancelButtonProps: { style: { float: "right", marginLeft: "10px" } }, // 취소 버튼을 오른쪽으로 이동
        okText: "확인", // 확인 버튼 텍스트
        cancelText: "취소", // 취소 버튼 텍스트
        onCancel: () => {
          // 취소 시 이전 dayRange 값으로 복원
          setDayRange(prevDayRange);
        },
      });
    } else if (value === "1000" && showWarning) {
      Modal.confirm({
        title: "경고",
        icon: <ExperimentOutlined />,
        maskClosable: true,
        content: (
          <div>
            환율 데이터를 7일간 예측한 결과를 출력합니다.
            <br />
            실제와 다를 수 있습니다.
            <br />
            그래도 계속 하시겠습니까?
          </div>
        ),
        onOk: () => {
          setShowWarning(false);
          setDayRange(value);
        },
        cancelButtonProps: { style: { float: "right", marginLeft: "10px" } }, // 취소 버튼을 오른쪽으로 이동
        okText: "확인", // 확인 버튼 텍스트
        cancelText: "취소", // 취소 버튼 텍스트
        onCancel: () => {
          // 취소 시 이전 dayRange 값으로 복원
          setDayRange(prevDayRange);
        },
      });
    } else {
      setDayRange(value);
	  
	  // 변경된 값을 세션 스토리지에 저장
      sessionStorage.setItem("dayRange", value);
      setDayRange(value);
    }
  };

  return (
    <div style={{ padding: "20px", backgroundColor: "lightgrey" }}>
      <Card bordered={false} style={{ width: "100%" }}>
        <Title level={4}>환율 정보: {currency}</Title>
        <div style={{ fontSize: "14px", color: "gray" }}>
          &nbsp;&nbsp;최소: {stats.min}, 최대: {stats.max}, 중앙값:{" "}
          {stats.median.toFixed(2)}, 평균: {stats.mean.toFixed(2)}, 최빈값:{" "}
          {Array.isArray(stats.mode) ? stats.mode.join(", ") : stats.mode}
        </div>
        <Select
          value={dayRange.toString()} // 현재 상태 값을 문자열로 변환하여 사용
          style={{ width: 120, marginBottom: 10, marginTop: 20 }}
          onChange={handleDayRangeChange}
        >
          <Option value="3">3일</Option>
          <Option value="5">5일</Option>
          <Option value="7">7일</Option>
          <Option value="30">30일</Option>
          <Option value="100">100일</Option>
          <Option value="365">365일</Option>
          <Option value="1000">
            환율 예측 <ExperimentOutlined />
          </Option>
        </Select>
        {/* 환율 예측 선택 시 안내 문구 표시 */}
        {dayRange === "1000" && (
          <span style={{ marginLeft: "20px" }}>
            <span>
              {dateRangeInfo.actualDataRange}(실제 데이터),{" "}
              {dateRangeInfo.forecastDataRange}(예측 데이터)입니다. 실제 환율과
              다를 수 있습니다.
            </span>
          </span>
        )}
        <div
          style={{ height: "400px", overflowX: "auto", overflowY: "hidden" }}
        >
          <ResponsiveLine
            data={data}
            // @nivo/line 그래프 설정
            margin={{ top: 80, right: 25, bottom: 60, left: 60 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "auto",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              orient: "bottom",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "날짜",
              legendOffset: 46, // 축 라벨 오프셋 조정
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "환율",
              legendOffset: -50, // 축 라벨 오프셋 조정
              legendPosition: "middle",
            }}
            colors={{ scheme: "nivo" }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={({ point }) => (
              <div
                style={{
                  background: "white",
                  padding: "9px 12px",
                  border: "1px solid #ccc",
				  zIndex: 9,
                }}
              >
                <div>날짜: {point.data.xFormatted}</div>
                <div>환율: {point.data.yFormatted}</div>
              </div>
            )}
			/>
        </div>
      </Card>
    </div>
  );
};

export default ExchangeInfo;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import { Layout, Menu } from "antd";
import {
  SearchOutlined,
  InfoCircleOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import Home from "./Home";
import SearchResults from "./SearchResults";
import ExchangeInfo from "./ExchangeInfo";
import ExchangeDefault from "./ExchangeDefault";
import Contact from "./Contact";
import ErrorPage from "./ErrorPage/ErrorPage";
import UnknownPage from "./ErrorPage/UnknownPage";

const { Header, Content } = Layout;

// 타이틀을 업데이트하는 컴포넌트
const TitleUpdater = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      document.title = "WTrip - 홈";
    } else if (location.pathname === "/contact") {
      document.title = "WTrip - 연락";
    }
  }, [location]);

  return null;
};

// ErrorCodeRoute 컴포넌트: errorCode가 숫자인지 확인
const ErrorCodeRoute = () => {
  const { errorCode } = useParams();

  // errorCode가 숫자인지 확인
  if (/^\d+$/.test(errorCode)) {
    return <ErrorPage />;
  } else {
    return <UnknownPage />;
  }
};

// NotFoundRedirect 컴포넌트
const NotFoundRedirect = () => {
  let navigate = useNavigate();
  useEffect(() => {
    navigate("/error/404"); // 정확한 경로로 리다이렉트
  }, [navigate]);

  return null;
};

// HeaderWithMenu 컴포넌트
const HeaderWithMenu = () => {
  const location = useLocation();

  const selectedKeys = (() => {
    if (location.pathname === "/" || location.pathname.startsWith("/country")) {
      return ["1"];
    } else if (location.pathname.startsWith("/exchange")) {
      return ["2"];
    } else if (location.pathname === "/contact") {
      return ["3"];
    } else {
      return [];
    }
  })();

  // 메뉴 아이템을 정의합니다.
  const menuItems = [
    {
      key: "1",
      icon: <SearchOutlined />,
      label: <Link to="/">Search</Link>,
    },
    {
      key: "2",
      icon: <InfoCircleOutlined />,
      label: <Link to="/exchange">Exchange Info</Link>,
    },
    {
      key: "3",
      icon: <ContactsOutlined />,
      label: <Link to="/contact">Contact</Link>,
    },
  ];

  return (
    <Header style={{ padding: 0, height: "32px" }}>
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={selectedKeys}
        items={menuItems}
        style={{ lineHeight: "32px" }}
      />
    </Header>
  );
};

// App 컴포넌트
const App = () => {
  return (
    <Router>
      <TitleUpdater />
      <Layout
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100vw",
          height: "100vh",
        }}
      >
        <HeaderWithMenu />
        <Content style={{ backgroundColor: "lightgrey", padding: 0 }}>
          <Routes>
            {/* 에러 페이지 */}
            <Route path="/error/:errorCode" element={<ErrorCodeRoute />} />
            <Route path="/error" element={<UnknownPage />} />

            {/* 루트 경로 */}
            <Route path="/" element={<Home />} />

            {/* 환율 */}
            <Route path="/exchange" element={<ExchangeDefault />} />
            <Route path="/exchange/:currency" element={<ExchangeInfo />} />

            {/* 국가 검색 결과 */}
            <Route path="/country/:countryName" element={<SearchResults />} />

            {/* 임시(연락) */}
            <Route path="/contact" element={<Contact />} />

            {/* 기타 나머지 경로 → 에러 페이지 */}
            <Route path="*" element={<NotFoundRedirect />} />
          </Routes>
        </Content>
      </Layout>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Card, Typography, Button, Modal, Select } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const ExchangeDefault = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `WTrip - 환율 정보`;
    }, []);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        if (selectedCurrency) {
            navigate(`/exchange/${selectedCurrency}`);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (value) => {
        setSelectedCurrency(value);
    };

    // 페이지 스타일
    const pageStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 32px)',
        padding: '20px',
        boxSizing: 'border-box',
        background: 'linear-gradient(to right, #6dd5ed, #2193b0)', // 선형 그라디언트
    };

    // 카드 스타일
    const cardStyle = {
        width: '100%',
        maxWidth: '400px',
        textAlign: 'center',
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        ':hover': {
            boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'
        }
    };

    return (
        <div style={pageStyle}>
            <Card style={cardStyle}>
                <GlobalOutlined style={{ fontSize: '2em' }} />
                <Title level={2}>환율 정보 서비스</Title>
                <Paragraph>다양한 국가의 환율 정보를 확인해보세요.</Paragraph>
                <Button type="primary" style={{ transition: 'all .2s' }} onClick={showModal}>통화 선택하기</Button>
            </Card>

            <Modal title="통화 선택" open={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Select style={{ width: '100%' }} onChange={handleChange} placeholder="통화를 선택하세요">
					<Option value="USD">미국 달러 (USD)</Option>
					<Option value="EUR">유로 (EUR)</Option>
					<Option value="JPY(100)">일본 엔 (JPY)</Option>
					<Option value="GBP">영국 파운드 (GBP)</Option>
					<Option value="AUD">호주 달러 (AUD)</Option>
					{/* 통화 옵션 */}
				</Select>
            </Modal>
        </div>
    );
};

export default ExchangeDefault;

import React, { useState, useEffect } from 'react';
import { AutoComplete, Row, Col } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import countriesData from './data/country.json';

const Container = styled.div`
  text-align: center;
  padding: 50px;
  background-color: lightgrey;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  color: #003366;
  margin-bottom: 20px;
`;

const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  max-width: 500px; // 최대 너비 설정
  height: 40px;
  .ant-input {
    height: 40px;
  }
`;

const Home = () => {
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate(); // useNavigate 훅 사용

  const onSelect = (value, option) => {
    navigate(`/country/${option.alpha3}`); // option.alpha3를 사용하여 navigate합니다.
  };

  useEffect(() => {
    const countryNames = countriesData.country.map(c => ({ value: c.name, alpha3: c.alpha3 }));
    setCountries(countryNames);
  }, []);

  return (
    <Container>
      <Title>해외 여행정보 검색</Title>
      <Row justify="center">
        <Col xs={24} sm={12} md={8} lg={6}>
          <StyledAutoComplete
            options={countries}
            onSelect={onSelect}
            placeholder="여행지 검색..."
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Home;

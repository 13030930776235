import React from 'react';

const Contact = () => {
  return (
    <div style={{ padding: '50px', textAlign: 'center' }}>
      <h1>Contact Page</h1>
      <p>이곳은 'Contact' 페이지입니다. 연락처 정보, 문의 양식 등 연락을 위한 정보를 제공할 수 있습니다.</p>
    </div>
  );
};

export default Contact;
